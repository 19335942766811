import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useRef,
  useCallback,
} from 'react';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import {
  BookingConfigEnum,
  BookingStepValueEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { BookingContext } from '../bookingContext/bookingContext';
import { BookingDataContext } from '../bookingDataContext/bookingDataContext';
import useApi from '../../shared/services/api.service';
import { BookingConfigContext } from '../bookingConfigContext/bookingConfigContext';
import { NotificationContext } from '../../shared/components/Notification/NotificationContext';
import { useDiscountDialog } from '../discountContext/discountContext';
import { BookingQuoteContext } from '../quoteContext/quoteContext';
import { PricingPayload } from '../../components/QuotationSummaryBlock/pricingPayload.interfaces';
import { getUrlPlatformAndCatalog } from '../../shared/helpers/url.helper';
import preparePricingPayload from '../../components/QuotationSummaryBlock/preparePricingPayload';
import compareBookingHelper from '../../shared/helpers/compareBooking.helper';
import { Currency } from '../../shared/consts/currency';
import { useSessionContext } from '../sessionContext/sessionContext';
import ErrorLoggingService from '../../shared/services/errorlogging.service';

export interface TotalPrice {
  commission: number;
  grossPremium: number;
  grossPremiumBeforePromotion: number;
  markUp: number;
  netPrice: number;
  promotion: boolean;
}

export interface PriceAdditionalValues {
  ACTIONPACK: string;
  CFAR: string;
  CRUISE: string;
  VALUABLES: string;
  WINTERSPORTS: string;
  agentId: string;
  base_price_breakdown: string;
  brand: string;
  cancellationLimit: string;
  creditAmount: string;
  discount_amount: string;
  excess: string;
  fctgRegion: string;
  finalNet: string;
  geographicalZone: string;
  gst_rate: string;
  med_cond_covered: string;
  medical_breakdown: string;
  medical_increase: string;
  productName: string;
  productType: string;
  promo_rate: string;
  salesChannel: string;
  sd_rate: string;
  shopId: string;
  tax_gst: string;
  tax_sd: string;
  visibility: string;
  warnings: string;
}

interface PriceSummaryContextType {
  getPricingData: () => void;
  getMedicalPricingData: () => void;
  price: number;
  currency: string;
  medicalIncrease: number;
  medicalBreakdown: string[];
  medicalConditionsCovered: MedicalConditionsCoveredStatus[];
  temporalMedicalBreakdown: string[];
  travelPrice: number;
  selectedCoverPrice: number;
  totalPrice: TotalPrice | null;
  netPrice: number | undefined;
  taxGst: number | undefined;
  taxStampDuty: number | undefined;
  excessPrice: number | undefined;
  cancellationLimitPrice: number | undefined;
  isSummaryValuesLoading: boolean;
  additionalPrices: PriceAdditionalValues | null;
  promoRate: string;
}

const PriceSummaryContext = createContext<PriceSummaryContextType | undefined>(
  undefined,
);

export type MedicalConditionsCoveredStatus = 'Y' | 'N' | 'S'; // Y-> Yes, covered, N-> not declared/added yet, S-> Excluded

export const PriceSummaryProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  // util
  const { showNotification } = useContext(NotificationContext);
  const { discountStatus } = useDiscountDialog();
  // context
  const { bookingConfigData } = useContext(BookingConfigContext);
  const { bookingSteps, update } = useContext(BookingContext);
  const { bookingDataResponse } = useContext(BookingDataContext);
  const { bookingQuoteResponse } = useContext(BookingQuoteContext);
  const { sessionParams } = useSessionContext();
  const API = useApi(bookingConfigData, sessionParams);
  const errorService: ErrorLoggingService = ErrorLoggingService.getInstance();
  // state
  const [previousPayload, setPreviousPayload] = useState<PricingPayload>(null!);
  const [previousMedicalScreeningId, setPreviousMedicalScreeningId] =
    useState<string>();
  const [previousDiscountStatus, setPreviousDiscountStatus] = useState('');
  const [isSummaryValuesLoading, setIsSummaryValuesLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState<TotalPrice | null>(null);
  const [selectedCoverPrice, setSelectedCoverPrice] = useState<number>(0);
  const [currency, setCurrency] = useState<string>(Currency.AUD);
  const [travelPrice, setTravelPrice] = useState<number>(0);
  const [medicalIncrease, setMedicalIncrease] = useState<number>(0);
  const [medicalBreakdown, setMedicalBreakdown] = useState<string[]>([]);
  const [medicalConditionsCovered, setMedicalConditionsCovered] = useState<
    MedicalConditionsCoveredStatus[]
  >([]);
  const [temporalMedicalBreakdown, setTemporalMedicalBreakdown] = useState<
    string[]
  >([]);
  const [additionalPrices, setAdditionalPrices] =
    useState<PriceAdditionalValues | null>(null);
  const [netPrice, setNetPrice] = useState<number | undefined>(undefined);
  const [taxGst, setPriceGst] = useState<number | undefined>(undefined);
  const [taxStampDuty, setStampDuty] = useState<number | undefined>(undefined);
  const [excessPrice, setExcessPrice] = useState<number | undefined>(undefined);
  const [cancellationLimitPrice, setCancellationLimitPrice] = useState<
    number | undefined
  >(undefined);
  const psClient = bookingConfigData[BookingConfigEnum.Channel]?.psClient;
  const { decodedSessionToken } = useSessionContext();
  // memo
  const promoRate =
    bookingSteps[BookingStepValueEnum.QuotationProposals][
      BookingSubStepValueEnum.PromoRate
    ] ?? '';

  const cancelTokenRef = useRef<CancelTokenSource | null>(null);

  const calculateSelectedCoverPrice = (
    grossPremiumBeforePromotion: number,
    priceAdditional: PriceAdditionalValues,
  ): number => {
    const additionalPricesArr = [
      'WINTERSPORTS',
      'CRUISE',
      'VALUABLES',
      'CFAR',
      'ACTIONPACK',
    ] as const;
    const totalDeductions = additionalPricesArr
      .map((key) => parseFloat(priceAdditional[key]) || 0)
      .reduce((total, priceAdd) => total + priceAdd, 0);

    const medicalIncreaseValue =
      parseFloat(priceAdditional.medical_increase) || 0;

    return +(
      grossPremiumBeforePromotion -
      totalDeductions -
      medicalIncreaseValue
    ).toFixed(2);
  };

  const getPricingData = (): void => {
    const apiCM360Url: string =
      bookingConfigData?.[BookingConfigEnum.DataCenter]?.cm360Endpoint;
    const url = `${apiCM360Url}/ui-proxy/ws-partners/api/${getUrlPlatformAndCatalog(
      bookingConfigData,
    )}/pricing`;
    const payload: PricingPayload = preparePricingPayload(
      bookingSteps,
      bookingConfigData,
      bookingDataResponse,
      bookingQuoteResponse,
      decodedSessionToken,
    );

    const isNewMedical =
      bookingDataResponse?.bookingData.pemcScreeningId !==
      previousMedicalScreeningId;

    // if payload is the same as previous one, do not call API
    if (
      compareBookingHelper(payload, previousPayload) &&
      (discountStatus === previousDiscountStatus ||
        discountStatus !== 'APPROVED') &&
      !isNewMedical
    ) {
      return;
    }

    setPreviousPayload(payload);
    setPreviousDiscountStatus(discountStatus);
    setPreviousMedicalScreeningId(
      bookingDataResponse?.bookingData.pemcScreeningId,
    );
    setIsSummaryValuesLoading(true);

    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel('Cancel previous request');
    }
    cancelTokenRef.current = axios.CancelToken.source();

    API.post(url, payload, {
      headers: {
        'Client-Id': psClient,
      },
      cancelToken: cancelTokenRef?.current?.token,
    })
      .then((response: any) => {
        const res = response.data;
        if (res.products[0].errorMessage) {
          showNotification(res.products[0].errorMessage, 'error', true);
        } else {
          const product = res.products[0];
          const priceNet = product.totalPrice.netPrice;
          const { additionalValues, price: priceObject } =
            res.products[0].productGroup[0];
          let medicalIncreaseValue = 0;

          setAdditionalPrices(product.productGroup[0].additionalValues[0].data);
          setTotalPrice(product.totalPrice);
          setCurrency(product.productGroup[0].price.currency);
          setPrice(priceNet);
          setNetPrice(priceObject.netPrice);
          setSelectedCoverPrice(
            calculateSelectedCoverPrice(
              product.totalPrice.grossPremiumBeforePromotion,
              product.productGroup[0].additionalValues[0].data,
            ),
          );
          if (additionalValues && additionalValues.length > 0) {
            medicalIncreaseValue = additionalValues[0].data.medical_increase;
            setMedicalIncrease(+medicalIncreaseValue);
            setMedicalBreakdown(
              (additionalValues[0].data.medical_breakdown ?? '').split('|'),
            );
            setMedicalConditionsCovered(
              (additionalValues[0].data.med_cond_covered ?? '').split('|'),
            );
          }
          setPriceGst(additionalValues[0].data.tax_gst ?? 0);
          setStampDuty(additionalValues[0].data.tax_sd ?? 0);
          setExcessPrice(additionalValues[0].data.excess ?? 0);
          setCancellationLimitPrice(
            +additionalValues[0].data.cancellationLimits,
          );
          if (
            bookingSteps[BookingStepValueEnum.QuotationProposals][
              BookingSubStepValueEnum.TotalPrice
            ] !== priceNet
          ) {
            update(
              priceNet,
              BookingStepValueEnum.QuotationProposals,
              BookingSubStepValueEnum.TotalPrice,
            );
          }
          if (
            additionalValues?.length &&
            bookingSteps[BookingStepValueEnum.CurrentStep] ===
              BookingStepValueEnum.QuotationInformation
          ) {
            update(
              additionalValues[0].data.medical_breakdown,
              BookingStepValueEnum.QuotationInformation,
              BookingSubStepValueEnum.MedicalPriceBreakdown,
            );
          }
        }
      })
      .catch((e: AxiosError) => {
        // TODO this is hotfix, fix QuotationSummary block on product change on step 2
        if (
          bookingSteps[BookingStepValueEnum.CurrentStep] !==
          BookingStepValueEnum.QuotationProposals
        ) {
          if (e.code !== 'ERR_CANCELED') {
            showNotification('unexpectedError', 'error', false);
          }
        }
      })
      .finally(() => {
        setIsSummaryValuesLoading(false);
      });
  };

  const getMedicalPricingData = useCallback(async (): Promise<void> => {
    const apiCM360Url: string =
      bookingConfigData?.[BookingConfigEnum.DataCenter]?.cm360Endpoint;
    const url = `${apiCM360Url}/ui-proxy/ws-partners/api/${getUrlPlatformAndCatalog(
      bookingConfigData,
    )}/pricing?useLatestCompanionsData=true`;
    try {
      setIsSummaryValuesLoading(true);
      const res = await API.post(
        url,
        preparePricingPayload(
          bookingSteps,
          bookingConfigData,
          bookingDataResponse,
          bookingQuoteResponse,
          decodedSessionToken,
        ),
        {
          headers: {
            'Client-Id': psClient,
          },
        },
      );
      if (!res.data.products[0].errorMessage) {
        const medicalBreakdownFromResponse =
          res.data.products[0].productGroup[0].additionalValues[0].data
            .medical_breakdown;
        if (medicalBreakdown) {
          setTemporalMedicalBreakdown(medicalBreakdownFromResponse.split('|'));
        } else {
          errorService.log('Medical pricing dont include medical_breakdown');
        }
      }
    } catch (err) {
      errorService.log(`Error while fetching medical pricing: ${err}`);
    } finally {
      setIsSummaryValuesLoading(false);
    }
  }, [
    bookingConfigData,
    bookingDataResponse,
    bookingQuoteResponse,
    errorService,
    psClient,
  ]);

  return (
    <PriceSummaryContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getPricingData,
        getMedicalPricingData,
        price,
        currency,
        medicalIncrease,
        medicalBreakdown,
        medicalConditionsCovered,
        temporalMedicalBreakdown,
        totalPrice,
        travelPrice,
        selectedCoverPrice,
        netPrice,
        taxGst,
        taxStampDuty,
        excessPrice,
        cancellationLimitPrice,
        isSummaryValuesLoading,
        additionalPrices,
        promoRate,
      }}
    >
      {children}
    </PriceSummaryContext.Provider>
  );
};

export const usePriceSummaryLoading = (): PriceSummaryContextType => {
  const context: PriceSummaryContextType | undefined =
    useContext(PriceSummaryContext);
  if (context === undefined) {
    throw new Error(
      'usePriceSummaryLoading must be used within a PriceSummaryProvider',
    );
  }
  return context;
};
